import React from "react";
import Navbar from "./navbar";

export default function Pagenotfound() {
  return (
    <div>
      <Navbar />
      page not found!
    </div>
  );
}
