import fireBefore from "../../assets/beforeafter/IMG_7323 - before.DNG";
import fireAfter from "../../assets/beforeafter/IMG_7388 - after.jpg";

import waterBefore from "../../assets/beforeafter/IMG_7323 - before.DNG";
import waterAfter from "../../assets/beforeafter/IMG_7388 - after.jpg";

import removalBefore from "../../assets/beforeafter/IMG_7323 - before.DNG";
import removalAfter from "../../assets/beforeafter/IMG_7388 - after.jpg";

import burgularyBefore from "../../assets/beforeafter/IMG_7323 - before.DNG";
import burgularyAfter from "../../assets/beforeafter/IMG_7388 - after.jpg";

export const beforeafter = [
  {
    name: "fire",
    before: fireBefore,
    after: fireAfter,
  },
  {
    name: "water",
    before: waterBefore,
    after: waterAfter,
  },
  {
    name: "removal",
    before: removalBefore,
    after: removalAfter,
  },
  {
    name: "Burgulary",
    before: burgularyBefore,
    after: burgularyAfter,
  },
];
